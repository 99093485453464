import { ReactElement } from 'react';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

const TrashIcon = (props: Omit<FontAwesomeIconProps, 'icon'>): ReactElement => (
  <FontAwesomeIcon icon={faTrash} {...props} />
);

export default TrashIcon;
