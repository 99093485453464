import { ReactElement, ReactNode } from 'react';

import { Container } from './styles';

interface CardProps {
  children: ReactNode;
}

const Card = (props: CardProps): ReactElement => <Container {...props} />;

export default Card;
