import { ReactElement, ReactNode, useState } from 'react';

import { Icon, Container, ListActions, UserImage, UserText } from './styles';

interface DropdownProp {
  name: string;
  image: string;
  children: ReactNode;
}

const Dropdown = ({ children, name, image }: DropdownProp): ReactElement => {
  const [isOpen, setOpen] = useState(true);

  function handleClick(e: React.MouseEvent): void {
    e.preventDefault();
    setOpen(!isOpen);
  }

  return (
    <Container onClick={handleClick}>
      <UserImage src={image} alt="Imagem do administrador" />
      <UserText>
        {name}
        <Icon />
      </UserText>
      <ListActions hidden={isOpen}>{children}</ListActions>
    </Container>
  );
};

export default Dropdown;
