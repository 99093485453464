import { ReactElement } from 'react';

import { ClipLoader } from 'react-spinners';

interface LoadingProps {
  isLoading?: boolean;
}

const Loading = ({ isLoading = true }: LoadingProps): ReactElement => (
  <ClipLoader size={50} color="#b88746" loading={isLoading} />
);

export default Loading;
