import { ReactElement, useMemo } from 'react';
import { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { useTranslated } from '~/hook/translated';
import { ModalConfirmAction } from '~/typings/Modal';
import { TableDataTypes, TableDetailsData } from '~/typings/Table';
import { getHeadersFromData } from '~/utils';
import { transformOnlyDate } from '~/utils/transformDate';

import { ActionButton, Container, Field, Footer, Group, Value } from './styles';

import ModalConfirm from '../ModalConfirm';

interface ModalComplement {
  modalTexts: string[];
}

interface ModalProps extends Partial<ModalComplement> {
  detailsTitle: string;
  actionIsDisabled?: boolean;
  actionConfirmDialog: ModalConfirmAction;
}

interface UserDetailsProps {
  modal: ModalProps;
}

/** @todo see if we can merge this with TeacherDetails */
const Details = ({
  modal: {
    detailsTitle,
    modalTexts,
    actionConfirmDialog,
    actionIsDisabled = false,
  },
}: UserDetailsProps): ReactElement => {
  const { push } = useHistory();
  const { translated, keysRemoved } = useTranslated();
  const [isModalVisible, setIsModalVisibility] = useState(false);
  const [mapped, setMapped] =
    useState<Map<string, TableDataTypes | Record<string, TableDataTypes>>>();

  const { state } = useLocation<TableDetailsData | undefined>();
  const tableData = state?.tableData;

  useEffect(() => {
    if (tableData) {
      const { date, price, rate, ...rest } = tableData;

      const tableEntries = new Map(Object.entries(rest));

      if (typeof date === 'string') {
        const reverseDate = date.split('/').reverse().join('/');
        const paymentDate = transformOnlyDate(reverseDate);
        tableEntries.set('date', paymentDate);
      }

      setMapped(tableEntries);
    }
  }, [tableData, translated]);

  if (!state) {
    push('/', { message: 'Plano não localizado.' });
  }

  const openModal = (): void => {
    if (modalTexts) {
      setIsModalVisibility(true);
    } else {
      actionConfirmDialog(true);
    }
  };

  const body = useMemo((): ReactElement[] | ReactElement => {
    if (!mapped || !tableData) return <h1>Sem dados</h1>;

    const headers = Array.from(mapped.keys());

    const customTranslated = new Map(translated);
    customTranslated.set('date', 'Data do pagamento');

    const headersTranslated = getHeadersFromData(customTranslated, tableData);
    const headersAllowed = headers.filter(key => !keysRemoved.includes(key));

    const content = headersAllowed.map(header => (
      <Group key={header}>
        <Field key={header}>{headersTranslated.get(header)}</Field>
        <Value>{mapped.get(header) ?? <em>Nada consta</em>}</Value>
      </Group>
    ));

    return content;
  }, [keysRemoved, mapped, tableData, translated]);

  return (
    <Container>
      {body}
      <Footer>
        <ActionButton
          disabled={actionIsDisabled}
          onClick={openModal}
          type="button"
        >
          {detailsTitle}
        </ActionButton>
      </Footer>
      {modalTexts && (
        <ModalConfirm
          action={actionConfirmDialog}
          texts={modalTexts}
          setVisibility={setIsModalVisibility}
          isVisible={isModalVisible}
        />
      )}
    </Container>
  );
};

export default Details;
