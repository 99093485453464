import { ReactElement, ReactNode, ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface ButtonTransparentProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

function ButtonTransparent({
  children,
  ...props
}: ButtonTransparentProps): ReactElement {
  return <Container {...props}>{children}</Container>;
}

export default ButtonTransparent;
