import styled from 'styled-components';

import { ChevronLeft } from '~/assets/icons';

export const Icon = styled(ChevronLeft)`
  margin-right: 1rem;
`;

export const Container = styled.button`
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #a67b27;
  margin-bottom: 32px;
  padding: 0.25em;

  border: 0.25rem dotted transparent;
  transition: border-color 250ms;

  &:hover,
  &:focus {
    border-color: #a67b27;
  }
`;
