import { ReactElement, useCallback } from 'react';

import { toast } from 'react-toastify';

import { PageComponent } from '~/components';
import { TeacherStatsFilter } from '~/filters';
import { useAuth } from '~/hook/auth';
import { teacherService } from '~/services/TeacherService';
import { callbackPageWithCount } from '~/typings/Table';

const TeacherStats = (): ReactElement => {
  const { token } = useAuth();

  const handlePageData = useCallback<callbackPageWithCount>(
    async ({ page, ...params }) => {
      try {
        const { data, lastPage } = await teacherService.getData({
          page,
          token,
          ...params,
        });

        const teacherData = TeacherStatsFilter(data);

        return { lastPage, data: teacherData };
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
      return { data: [], lastPage: 0 };
    },
    [token],
  );

  return (
    <PageComponent
      search={{
        name: 'queryTeacherStats',
        placeholder: 'Buscar por professor ou CREF',
      }}
      title="Estatísticas dos professores"
      {...{ handlePageData }}
    />
  );
};

export default TeacherStats;
