import { ReactElement, useCallback, useMemo, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DetailsWrapper, Details } from '~/components';
import { useAuth } from '~/hook/auth';
import { PageDownload } from '~/pages';
import FinancialService from '~/services/FinancialService';
import { ModalConfirmAction } from '~/typings/Modal';
import { TableDetailsData } from '~/typings/Table';

const ReportExport = (): ReactElement => {
  const { state } = useLocation<TableDetailsData | undefined>();
  const [showPageDownload, setVisibilityPageDownload] = useState(false);
  const [url, setUrl] = useState<string>();
  const { token } = useAuth();
  const params = useParams<{ id: string }>();

  const action: ModalConfirmAction = useCallback(() => {
    if (state) {
      setVisibilityPageDownload(true);
    }
  }, [state]);

  const handleDownload = useCallback(async () => {
    try {
      const professionalId = parseInt(params.id, 10);

      if (Number.isNaN(professionalId)) {
        throw new Error('Id inválido');
      }

      const response = await FinancialService.downloadReport(
        {
          token,
        },
        professionalId,
      );
      const fileUrlString = URL.createObjectURL(response);
      setUrl(fileUrlString);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  }, [params.id, token]);

  const body = useMemo(
    () => <PageDownload {...{ handleDownload, url, setUrl }} />,
    [handleDownload, url],
  );

  if (showPageDownload) {
    return body;
  }

  return (
    <DetailsWrapper title="Detalhes">
      <Details
        modal={{
          detailsTitle: 'Exportar',
          actionConfirmDialog: action,
        }}
      />
    </DetailsWrapper>
  );
};

export default ReportExport;
