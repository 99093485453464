import Select from 'react-select';
import styled, { css } from 'styled-components';

interface SelectError {
  hasError?: boolean;
}

export const Selector = styled(Select)<SelectError>`
  width: 100%;
  ${props =>
    props.hasError &&
    css`
      .react-select__control {
        border-color: red;
      }
    `}
`;
