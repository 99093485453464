import styled from 'styled-components';

export const Container = styled.button`
  font-weight: bold;
  color: white;
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 0rem;
  border-radius: 5rem;
  background-color: ${props => props.theme.colors.primary};
  border: none;
`;
