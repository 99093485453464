type formatDateArg = Pick<
  Intl.DateTimeFormatOptions,
  'dateStyle' | 'timeStyle'
>;
function getFormatDate({
  dateStyle,
  timeStyle,
}: formatDateArg): Intl.DateTimeFormat['format'] {
  const { format } = Intl.DateTimeFormat('pt-BR', { dateStyle, timeStyle });
  return format;
}

export default function transformfullTime(dateString: string): string {
  try {
    const date = Date.parse(dateString);
    const formatFullDate = getFormatDate({
      dateStyle: 'full',
      timeStyle: 'medium',
    });
    const dateFormatted = formatFullDate(date);
    return dateFormatted;
  } catch (e) {
    return dateString;
  }
}

export function transformShortDate(dateString: string): string {
  const dateJoin = dateString.replace(/\s/, 'T');
  const date = new Date(dateJoin);
  const formatShortDate = getFormatDate({
    dateStyle: 'short',
  });
  const dateFormatted = formatShortDate(date);
  return dateFormatted;
}

// transform a date string to a date object and return it
export function transformOnlyDate(dateString: string): string {
  const date = new Date(dateString);
  const formatOnlyDate = getFormatDate({ dateStyle: 'full' });
  const dateFormatted = formatOnlyDate(date);
  return dateFormatted;
}
