import { ReactElement } from 'react';

import { SubmitHandler } from '@unform/core';

import { SearchIcon } from '~/components';

import { SearchForm, SearchInput, SearchLabel } from './styles';

interface SearchProps {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  query?: string;
  updateQuery?: SubmitHandler;
  initialData?: Record<string, string | undefined>;
}

const Search = ({
  updateQuery = () => null,
  query,
  name,
  placeholder,
  initialData,
  ...rest
}: SearchProps): ReactElement => (
  <SearchForm {...{ initialData }} onSubmit={updateQuery}>
    <SearchLabel>
      <SearchIcon />
      <SearchInput
        defaultValue={query}
        {...{ name, placeholder, ...rest }}
        type="query"
        minLength={3}
        size={30}
        required
      />
    </SearchLabel>
  </SearchForm>
);

export default Search;
