import { ReactElement } from 'react';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

const CloseIcon = (props: Omit<FontAwesomeIconProps, 'icon'>): ReactElement => (
  <FontAwesomeIcon icon={faTimesCircle} {...props} />
);

export default CloseIcon;
