import { ReactElement } from 'react';

import { useAuth } from '~/hook/auth';

import { Container } from './styles';

const AccountDropdown = (): ReactElement => {
  const { signOut } = useAuth();

  return <Container onClick={signOut}>Sair</Container>;
};

export default AccountDropdown;
