import styled from 'styled-components';

export const Container = styled.button`
  padding: 1rem;
  border: 0.25rem dotted transparent;
  box-shadow: none;
  background: none;
  &:focus,
  &:hover {
    border-color: #2c2c2c;
  }
  & + & {
    margin-left: 1rem;
  }
`;
