import { ReactElement } from 'react';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

const ChevronRight = (
  props: Omit<FontAwesomeIconProps, 'icon'>,
): ReactElement => <FontAwesomeIcon icon={faChevronRight} {...props} />;

export default ChevronRight;
