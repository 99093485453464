export const { format: monthFormater } = new Intl.DateTimeFormat('pt-BR', {
  month: 'long',
});

export default function getMounthNames(): string[] {
  const months = new Array(12).fill(null);
  const monthsName = months.map((_, month) => {
    const utc = Date.UTC(2021, month, 2);
    const date = new Date(utc);
    const name = monthFormater(date);
    const capitalizeName = `${name[0].toUpperCase()}${name.slice(1)}`;
    return capitalizeName;
  });

  return monthsName;
}
