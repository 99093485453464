import { ReactElement } from 'react';

import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

const SliderIcon = (
  props: Omit<FontAwesomeIconProps, 'icon'>,
): ReactElement => <FontAwesomeIcon {...props} icon={faSlidersH} />;

export default SliderIcon;
