import { ReactElement, useRef } from 'react';

import { DocumentIcon, GraphicBarIcon, MoneyBagIcon } from '~/assets/icons';
import { Header, List } from '~/components';
import { NavLinkProps } from '~/components/List';
import { useAuth } from '~/hook/auth';
import DashboardRoute from '~/routes/dashboard';

import { Container, Content, Sidebar } from './styles';

const Dashboard = (): ReactElement => {
  const items = useRef<NavLinkProps[]>([
    {
      texts: ['Relatório'],
      image: DocumentIcon,
      url: '/dashboard/report',
    },
    {
      texts: ['Transações', 'financeiras'],
      image: MoneyBagIcon,
      url: '/dashboard/financial',
    },
    {
      texts: ['Estatísticas', 'de professores'],
      image: GraphicBarIcon,
      url: '/dashboard/teacher-stats',
    },
  ]);

  const {
    user: { name },
  } = useAuth();

  return (
    <Container>
      <Header {...{ name }} />
      <Sidebar>
        <List items={items.current} />
      </Sidebar>
      <Content>
        <DashboardRoute />
      </Content>
    </Container>
  );
};

export default Dashboard;
