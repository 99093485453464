import styled from 'styled-components';

import { Input } from '~/components';

export const Container = styled.section`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 2rem;
`;

export const Title = styled.h1`
  font-size: 2rem;
  color: black;
`;

export const InputMonth = styled(Input)`
  grid-column: 1 / span 2;
`;

export const Group = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  /* grid-template-rows: repeat(auto-fill, minmax(1fr, 100px)); */
  min-width: 300px;
  border-bottom: thin solid #2c2c2c;
`;
