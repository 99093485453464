import { ReactElement, useMemo, useRef } from 'react';

import { MoneyBagGoldIcon, UserGoldIcon } from '~/assets/icons';
import { TitleDashboard } from '~/components';

import {
  Card,
  CardTitle,
  Container,
  GroupCard,
  Image,
  Link,
  Subtitle,
  Title,
  GroupText,
} from './styles';

interface CardProps {
  image: string;
  imageAlt: string;
  title: string;
  pathname: string;
}

const Report = (): ReactElement => {
  const cardsProps = useRef<CardProps[]>([
    {
      image: UserGoldIcon,
      imageAlt: 'Símbolo do usuário',
      pathname: 'teacher',
      title: 'Usuário e Professores',
    },
    {
      image: MoneyBagGoldIcon,
      imageAlt: 'Símbolo de uma bolsa de dinheiro',
      pathname: 'financial',
      title: 'Relatório financeiro',
    },
  ]);

  const cards = useMemo(
    () =>
      cardsProps.current.map(({ image, imageAlt, title, pathname }) => (
        <Link to={`report/${pathname}`} key={pathname}>
          <Card>
            <Image src={image} alt={imageAlt} />
            <GroupText>
              <Title>{title}</Title>
              <Subtitle>Exportar</Subtitle>
            </GroupText>
          </Card>
        </Link>
      )),
    [],
  );

  return (
    <Container>
      <CardTitle>
        <TitleDashboard>Relatórios</TitleDashboard>
      </CardTitle>
      <GroupCard>{cards}</GroupCard>
    </Container>
  );
};

export default Report;
