import { FinancialData, FinancialResponse } from '~/models/Financial';
import { stringToCpf, transformToCurrency } from '~/utils';
import { transformShortDate } from '~/utils/transformDate';

function convertNumberToMoney(collection: FinancialData[]): FinancialData[] {
  const currencyFormat = transformToCurrency();

  const out = collection.map(({ sum, ...rest }) => ({
    ...rest,
    sum: currencyFormat(+sum),
  }));
  return out;
}

interface FinancialFilterArg {
  data: FinancialResponse[];
  translated: Map<string, string>;
}

export default function FinancialFilter({
  data,
  translated,
}: FinancialFilterArg): FinancialData[] {
  const tableData: FinancialData[] = data.map(
    ({ price, updated_at, id, type, rate, cpf, ...rest }) => ({
      id,
      date: transformShortDate(updated_at),
      sum: price.toString(),
      cpf: stringToCpf(cpf),
      type: translated.get(type) ?? 'NÃO DEFINIDO',
      ...rest,
    }),
  );

  const tableDataCurrency = convertNumberToMoney(tableData);

  return tableDataCurrency;
}
