import { ReactElement } from 'react';

import { Switch } from 'react-router-dom';

import * as Page from '~/pages';

import Route from './route';

const Routes = (): ReactElement => (
  <Switch>
    {/* Route public */}
    <Route path="/" exact component={Page.SignIn} />

    {/* Route private */}
    <Route path="/dashboard" isPrivate component={Page.Dashboard} />

    {/* <Route path="*" component={() => <span>Não existe essa rota</span>} /> */}
  </Switch>
);

export default Routes;
