import styled from 'styled-components';

export const Container = styled.header`
  position: sticky;
  top: 0;
  background-color: rgba(44, 44, 44, 0.95);
  backdrop-filter: blur(2px);
  display: flex;
  flex: 0 0 100%;
  height: 100px;
  z-index: 1;

  @media (min-width: 1025px) {
    padding-right: 2rem;
  }
`;

export const LogoContainer = styled.div`
  padding: 2rem;
  background-color: #1c1c1c;
  width: 200px;
`;

export const Logo = styled.img`
  display: block;
  margin: auto;
  max-width: 100px;
  width: 100%;
  height: auto;
`;
