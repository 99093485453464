import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SliderIcon, TrashIcon } from '~/assets/icons';
import {
  ButtonTransparent,
  BackButton,
  Modal,
  Table,
  TitleDashboard,
  ReportFilterDialog,
} from '~/components';
import { useAuth } from '~/hook/auth';
import { Either } from '~/models/Common';
import ResourceService from '~/services/ResourceService';
import { callbackPageWithCount, TableData } from '~/typings/Table';

import {
  Card,
  Container,
  Form,
  Group,
  Header,
  Input,
  Label,
  Search,
  SearchIcon,
} from './styles';

interface ReportProps {
  title: string;
  service: ResourceService<TableData>;
  handleTableData?(data: TableData[]): TableData[];
}

interface QueryName {
  queryByName: string;
}

interface Query {
  query: string;
}

function ReportDetails({
  title,
  service,
  handleTableData,
}: ReportProps): ReactElement {
  const formRef = useRef<FormHandles>(null);
  const { search } = useLocation();
  const [queryDate, setQueryDate] = useState<Date>();
  const { push } = useHistory();
  const [isTableVisible, setVisibilityTable] = useState(false);
  const [isFilterVisible, setFilterVisibility] = useState(false);
  const { token } = useAuth();
  const [query, setQuery] = useState<Either<Query, QueryName> | undefined>(
    () => {
      const queryParam = new URLSearchParams(search);
      const value = queryParam.get('queryByName');

      if (value) {
        return { queryByName: value };
      }

      return undefined;
    },
  );

  useEffect(() => {
    if (query) {
      const newSearchParams = new URLSearchParams(search);
      if (query.query) {
        newSearchParams.set('query', query.query);

        if (newSearchParams.has('queryByName')) {
          newSearchParams.delete('queryByName');
        }
      } else if (query.queryByName) {
        newSearchParams.set('queryByName', query.queryByName);

        if (newSearchParams.has('query')) {
          newSearchParams.delete('query');
        }
      }
      push({ search: newSearchParams.toString() });
    }
  }, [push, query, search]);

  const handleQueryCref: SubmitHandler<Query> = useCallback(
    ({ query: queryCref }): void => {
      setQuery({ query: queryCref });
    },
    [],
  );

  const handleSubmit = useCallback<SubmitHandler<QueryName>>(
    ({ queryByName }) => {
      setQuery({ queryByName });

      setVisibilityTable(true);
    },
    [],
  );

  const handleOpenFilter = (): void => {
    setFilterVisibility(true);
  };

  const handlePageData = useCallback<callbackPageWithCount>(
    async ({ page }) => {
      try {
        const { lastPage, data: preData } = await service.getData({
          page,
          token,
          queryByDate: queryDate,
          ...query,
        });

        const data = handleTableData ? handleTableData(preData) : preData;

        return { data, lastPage };
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
      }

      return { data: [], lastPage: 0 };
    },
    [handleTableData, query, queryDate, service, token],
  );

  const handleClear = useCallback((): void => {
    setVisibilityTable(false);
    push({ search: '' });
    setQuery(undefined);
    formRef.current?.reset();
  }, [push]);

  useEffect(() => {
    formRef.current?.submitForm();
  }, []);

  const formEl = useMemo(
    () => (
      <Form ref={formRef} onSubmit={handleSubmit} initialData={query}>
        <Label>
          Professor (a):
          <Group>
            <Input
              name="queryByName"
              type="query"
              // minLength={3}
              maxLength={255}
              placeholder="Nome do professor"
            />
            <ButtonTransparent type="submit">
              <SearchIcon />
            </ButtonTransparent>
            <ButtonTransparent type="reset" onClick={handleClear}>
              <TrashIcon />
            </ButtonTransparent>
          </Group>
        </Label>
      </Form>
    ),
    [handleClear, handleSubmit, query],
  );

  const secondCard = useMemo(
    () =>
      isTableVisible && (
        <Card>
          <Header>
            <ButtonTransparent type="button" onClick={handleOpenFilter}>
              <SliderIcon />
            </ButtonTransparent>
            <Search
              initialData={query}
              name="query"
              placeholder="Buscar por professor ou CREF"
              updateQuery={handleQueryCref}
            />
          </Header>
          <Table {...{ handlePageData }} />
          {isFilterVisible && (
            <Modal
              isVisible={isFilterVisible}
              setVisibility={setFilterVisibility}
            >
              <ReportFilterDialog
                setDateFilter={setQueryDate}
                setVisibility={setFilterVisibility}
              />
            </Modal>
          )}
        </Card>
      ),
    [handlePageData, handleQueryCref, isFilterVisible, isTableVisible, query],
  );

  return (
    <Container>
      <BackButton pushToRoot />
      <Card>
        <TitleDashboard>{title}</TitleDashboard>
        {formEl}
      </Card>
      {secondCard}
    </Container>
  );
}

export default ReportDetails;
