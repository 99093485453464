import { ServiceArgs } from '~/typings/Services';

import { callbackPageWithCountArgs, Page, TableData } from '../typings/Table';
import Service from './Service';

type GetArgs = callbackPageWithCountArgs & ServiceArgs;

export default abstract class ResourceService<
  T extends TableData,
> extends Service {
  protected static readonly PER_PAGE = 15;

  async getData({
    token,
    signal,
    queryByDate,
    ...rest
  }: GetArgs): Promise<Page<T> | never> {
    const entries = Object.entries(rest);
    const entriesWithoutUndefined = entries.filter(
      ([, value]) => value !== undefined,
    );
    const params = new URLSearchParams(entriesWithoutUndefined);

    if (queryByDate) {
      const dateYearMouth = queryByDate.toISOString().substr(0, 7);
      params.set('queryByDate', dateYearMouth);
    }

    params.set('perpage', ResourceService.PER_PAGE.toString());

    const response = await Service.api.fetchAndReturnJSON<Page<T>>({
      pathname: this.pathname,
      params,
      token,
      signal,
      ...rest,
    });

    return response;
  }
}
