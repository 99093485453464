import { ReactNode, ReactElement } from 'react';

import { Card, BackButton } from '~/components';

import { Container, Title } from './styles';

interface DetailsProps {
  title: string;
  children?: ReactNode;
  footer?: ReactNode;
}

const DetailsWrapper = ({
  title,
  children,
  footer,
}: DetailsProps): ReactElement => (
  <Container>
    <BackButton />
    <Card>
      <Title>{title}</Title>
      {children}
    </Card>
    {footer}
  </Container>
);
export default DetailsWrapper;
