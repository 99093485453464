interface apiPropsComplement {
  method: 'GET' | 'POST' | 'PATCH';
  body: string;
  signal: AbortSignal;
  token: string;
  params: URLSearchParams;
}

type ApiProps = Partial<apiPropsComplement> & {
  pathname: string;
};

export const getCancelToken = (): AbortController => new AbortController();

export class Api {
  private response?: Response;

  private request?: Request;

  private static getUrl({
    pathname,
    params,
  }: Pick<ApiProps, 'pathname' | 'params'>): URL {
    const baseURL = process.env.REACT_APP_BASE_URL;
    if (!baseURL) throw new Error('baseUrl problem');

    const { href: basePathname } = new URL(baseURL);
    const url = new URL(basePathname + pathname, baseURL);

    if (params) {
      params.forEach((value, key) => {
        url.searchParams.set(key, value);
      });
    }

    return url;
  }

  private static getHeaders({ token }: Pick<ApiProps, 'token'>): Headers {
    const headers = new Headers({
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
    });

    if (token) {
      headers.set('Authorization', token);
    }

    return headers;
  }

  private prepareRequest({ params, pathname, token, ...rest }: ApiProps): void {
    const url = Api.getUrl({ params, pathname });
    const headers = Api.getHeaders({ token });

    this.request = new Request(url.href, {
      headers,
      ...rest,
    });
  }

  private async fetchResponse(): Promise<void> {
    if (!this.request) throw new Error('Without request');

    this.response = await fetch(this.request);

    if (!this.response.ok) {
      let responseServer: string | undefined;

      try {
        const responseObject = await this.response.json();
        if ('message' in responseObject) {
          responseServer = responseObject.message;
        }
      } catch (e) {
        responseServer = this.response.statusText;
      }

      throw new Error(responseServer);
    }
  }

  private async prepareSetAndFetch(apiProps: ApiProps): Promise<void> {
    this.prepareRequest(apiProps);
    await this.fetchResponse();
  }

  public async fetchAndReturnJSON<T>(apiProps: ApiProps): Promise<T> {
    await this.prepareSetAndFetch(apiProps);
    if (!this.response) throw new Error('Without response');

    const response = (await this.response.json()) as T;
    return response;
  }

  public async fetchAndReturnBlob(apiProps: ApiProps): Promise<Blob> {
    await this.prepareSetAndFetch(apiProps);
    if (!this.response) throw new Error('Without response');

    const response = (await this.response.blob()) as Blob;
    return response;
  }
}
