import { ReactElement } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import {
  Financial,
  FinancialDetails,
  Report,
  TeacherStats,
  TeacherStatsDetails,
  ReportFinancial,
} from '~/pages';
import ReportExport from '~/pages/ReportExport';
import ReportTeacher from '~/pages/ReportTeacher';

import Route from './route';

const DashboardRoute = (): ReactElement => (
  <Switch>
    <Route
      exact
      isPrivate
      path="/dashboard"
      component={() => <Redirect to="/dashboard/report" />}
    />

    <Route path="/dashboard/report" isPrivate exact component={Report} />

    <Route
      path="/dashboard/report/teacher"
      isPrivate
      exact
      component={ReportTeacher}
    />

    <Route
      path="/dashboard/report/teacher/:id"
      isPrivate
      exact
      component={ReportExport}
    />

    <Route
      path="/dashboard/report/financial"
      isPrivate
      exact
      component={ReportFinancial}
    />

    <Route
      path="/dashboard/report/financial/:id"
      isPrivate
      exact
      component={ReportExport}
    />

    <Route path="/dashboard/financial" isPrivate exact component={Financial} />

    <Route
      path="/dashboard/financial/:financialId"
      isPrivate
      exact
      component={props => <FinancialDetails {...props} />}
    />

    <Route
      path="/dashboard/teacher-stats"
      isPrivate
      exact
      component={TeacherStats}
    />

    <Route
      path="/dashboard/teacher-stats/:teacherId"
      isPrivate
      exact
      component={TeacherStatsDetails}
    />

    <Route path="*" isPrivate component={() => <Redirect to="/dashboard" />} />
  </Switch>
);

export default DashboardRoute;
