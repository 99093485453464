import { ReactElement, useCallback } from 'react';

import { ReportDetails } from '~/components';
import { FinancialFilter } from '~/filters';
import { useTranslated } from '~/hook/translated';
import { FinancialData, FinancialResponse } from '~/models/Financial';
import { financialService } from '~/services/FinancialService';

interface FilterTableData {
  (data: FinancialResponse[]): FinancialData[];
}

const ReportFinancial = (): ReactElement => {
  const { translated } = useTranslated();

  const handleTableData = useCallback<FilterTableData>(
    data => {
      const tableDataCurrency = FinancialFilter({
        data,
        translated,
      });

      return tableDataCurrency;
    },
    [translated],
  );

  return (
    <ReportDetails
      {...{ handleTableData }}
      service={financialService}
      title="Relatório Financeiro"
    />
  );
};

export default ReportFinancial;
