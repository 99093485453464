import { ReactElement } from 'react';

import { Container, Image, Text, SubText } from './styles';

interface GoldButtonProp {
  icon: string;
  text: string;
  value: number | string;
}

const GoldButton = ({ icon, text, value }: GoldButtonProp): ReactElement => (
  <Container>
    <Image src={icon} alt={`ícone referente a ${text}.`} />
    <Text>
      {value} <SubText>{text}</SubText>
    </Text>
  </Container>
);

export default GoldButton;
