import { Form as FormBase } from '@unform/web';
import styled from 'styled-components';

import { Search as SearchBase } from '~/components';
import {
  Input as InputBase,
  Card as CardBase,
  SearchIcon as SearchIconBase,
} from '~/components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
`;
export const Card = styled(CardBase)`
  width: 100%;
  max-width: 800px;
  padding: 2rem;
  margin-top: 3rem;
`;
export const Group = styled.span`
  position: relative;
  display: flex;
  width: min-content;
  justify-content: center;
  padding: 0.75rem 2rem;
  margin-top: 1rem;
  border-bottom: 1px solid #2c2c2c;
`;
export const Input = styled(InputBase)`
  border: none;
`;
export const Label = styled.label`
  font-weight: bold;
`;
export const Form = styled(FormBase)`
  margin-top: 3rem;
`;
export const Search = styled(SearchBase)`
  margin-left: 1rem;
`;
export const SearchIcon = styled(SearchIconBase)`
  width: 1.75rem;
  height: 1.75rem;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;
