import { ReactElement, useCallback } from 'react';

import { toast } from 'react-toastify';

import { PageComponent } from '~/components';
import { FinancialFilter } from '~/filters';
import { useAuth } from '~/hook/auth';
import { useTranslated } from '~/hook/translated';
import { financialService } from '~/services/FinancialService';
import { callbackPageWithCount } from '~/typings/Table';

const Financial = (): ReactElement => {
  const { token } = useAuth();
  const { translated } = useTranslated();

  const handlePageData = useCallback<callbackPageWithCount>(
    async ({ page, ...params }) => {
      try {
        const { data: dataResponse, lastPage } = await financialService.getData(
          {
            page,
            token,
            ...params,
          },
        );

        const tableDataCurrency = FinancialFilter({
          data: dataResponse,
          translated,
        });

        return { lastPage, data: tableDataCurrency };
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }

      return { lastPage: 0, data: [] };
    },
    [token, translated],
  );

  return (
    <PageComponent
      search={{
        name: 'queryFinancial',
        placeholder: 'Buscar por professor ou CREF',
      }}
      title="Transações financeiras"
      {...{ handlePageData }}
    />
  );
};

export default Financial;
