import { createGlobalStyle } from 'styled-components';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/700.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  [hidden] {
    display: none !important;
  }

  html {
    font-size: 62.5%; /* 1rem = 10px */
    height: 100%;
    user-select: none;
  }

  body {
    height: auto;
    background: ${props => props.theme.colors.background};
    color: #000000;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }

  body, #root {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  #root {
    height: 100%;
  }

  body, input, button {
    font: 1.6rem 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.48;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    background: none;
    font-weight: 700;
    cursor: pointer;
    border: 0;
    transition: 180ms ease-in-out;
  }

  button {
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    list-style: none;
    text-align: left;
    padding: 0;
  }

  :root {
    scrollbar-color: #b88746 #2c2c2c;
    scrollbar-width: thick;

    &::-webkit-scrollbar  {
      width: 1rem;
    }
    &::-webkit-scrollbar-track  {
      background-color: #2c2c2c;
    }
    &::-webkit-scrollbar-thumb  {
      background-color: #b88746;
    }
  }

  button[disabled] {
    cursor: not-allowed;
  }
`;
