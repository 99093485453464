import styled, { keyframes } from 'styled-components';

interface ModalAnimationProps {
  animationDurationMiliseconds: number;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.section<ModalAnimationProps>`
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  background: rgba(44, 44, 44, 0.5);
  backdrop-filter: blur(5px);
  animation: ${props => props.animationDurationMiliseconds}ms ${fadeIn}
    ease-in-out;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 3rem 4rem;
  max-width: 600px;
  background-color: white;
  border-radius: 0.25rem;
  color: #2c2c2c;
  text-align: center;
`;

export const ButtonClose = styled.button`
  position: absolute;
  font-size: 3rem;
  line-height: 3rem;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
`;
