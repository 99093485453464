import { createContext, ReactElement, ReactNode, useContext } from 'react';

import { ThemeProvider } from 'styled-components';

import DefaultTheme from '~/styles/default.theme';

interface ThemeContextData {
  currentTheme: 'default';
}

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

interface ThemeProviderProps {
  children: ReactNode;
}

const AppThemeProvider = ({ children }: ThemeProviderProps): ReactElement => (
  <ThemeContext.Provider value={{ currentTheme: 'default' }}>
    <ThemeProvider theme={DefaultTheme}>{children}</ThemeProvider>
  </ThemeContext.Provider>
);

const useTheme = (): ThemeContextData => {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error('useTheme must be used within an AppThemeProvider');
  }

  return themeContext;
};

export { AppThemeProvider, useTheme };
