import { ReactElement } from 'react';

import { Text } from '~/components';

import { Container, Image, NavLink, TextWrapper } from './styles';

export interface NavLinkProps {
  image: string;
  texts: string[];
  url: string;
}

interface ListProp {
  items: NavLinkProps[];
}

const List = ({ items = [] }: ListProp): ReactElement => {
  const itemsEl = items.map(({ image, texts, url }) => (
    <NavLink to={url} key={url}>
      <Image src={image} alt="Imagem referente ao um item do menu" />
      <TextWrapper>
        <Text {...{ texts }} />
      </TextWrapper>
    </NavLink>
  ));

  return <Container>{itemsEl}</Container>;
};

export default List;
