import styled from 'styled-components';

export const Title = styled.h4`
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Subtitle = styled.p`
  font-size: 1.25rem;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  font-family: 'Open Sans', sans-serif;
  border: none;
  border-bottom: 1px solid #2c2c2c;
  color: #2c2c2c;

  &::placeholder {
    opacity: 1;
  }

  & + & {
    margin-top: 1rem;
  }
`;
