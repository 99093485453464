import { TeacherData, TeacherStats } from '~/models/Teacher';
import { ServiceArgs } from '~/typings/Services';

import ResourceService from './ResourceService';
import Service from './Service';

interface ShowArg extends ServiceArgs {
  id: string;
}

export default class TeacherServiceImpl extends ResourceService<TeacherData> {
  async show({ id, ...rest }: ShowArg): Promise<TeacherStats> {
    const response = await Service.api.fetchAndReturnJSON<TeacherStats>({
      pathname: `${this.pathname}/${id}`,
      ...rest,
    });

    return response;
  }

  static async downloadReport(
    rest: ServiceArgs,
    params?: URLSearchParams,
  ): Promise<Blob> {
    const response = await Service.api.fetchAndReturnBlob({
      pathname: `/admins/reports/professionals/students`,
      params,
      ...rest,
    });

    return response;
  }
}

export const teacherService = new TeacherServiceImpl('/admins/professionals');
