import { ReactElement, useCallback, useState } from 'react';

import { useAuth } from '~/hook/auth';
import { PageDownload } from '~/pages';
import TeacherService from '~/services/TeacherService';

function ReportTeacher(): ReactElement {
  const { token } = useAuth();
  const [url, setUrl] = useState<string>();

  const handleDownload = useCallback(
    async (query?: string): Promise<void> => {
      const params = new URLSearchParams({ queryByName: query || '' });

      const response = await TeacherService.downloadReport({ token }, params);
      const fileUrlString = URL.createObjectURL(response);
      setUrl(fileUrlString);
    },
    [token],
  );

  return <PageDownload {...{ handleDownload, url, setUrl }} showFilter />;
}

export default ReportTeacher;
