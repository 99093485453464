import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import Paginate, { ReactPaginateProps } from 'react-paginate';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';

import { Loading } from '~/components';
import { useTranslated } from '~/hook/translated';
import {
  TableData,
  callbackPageWithCount,
  callbackPageWithCountArgs,
} from '~/typings/Table';
import { getHeadersFromData } from '~/utils';

import {
  Container,
  TableBody,
  TableCell,
  TableCellIcon,
  TableHeader,
  TableHeaderCell,
  TableHeaderCellIcon,
  TableRow,
  TableIcon,
  TableFooter,
  Wrapper,
  PaginateWrapper,
} from './styles';

interface TableProps {
  handlePageData: callbackPageWithCount;
  query?: string;
}

interface callbackUpdate {
  (args: callbackPageWithCountArgs): Promise<void>;
}

interface HandlerLink {
  (tableData: TableData): void;
}

/**
 * Tabela ja com paginacao
 */
const Table = ({ handlePageData, query }: TableProps): ReactElement => {
  const [isLoading, setLoading] = useState(false);
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const { translated } = useTranslated();

  const [pageCount, setPageCount] = useState(0);

  const [tableDataPage, setTableDataPage] = useState<TableData[]>();

  const updateTable = useCallback<callbackUpdate>(
    async ({ page }) => {
      setLoading(true);
      const { data: table, lastPage } = await handlePageData({ page, query });
      setTableDataPage(lastPage > 0 ? table : undefined);
      setPageCount(lastPage);
      setLoading(false);
    },
    [handlePageData, query],
  );

  useEffect(() => {
    updateTable({ page: 1, query });
  }, [query, updateTable]);

  const handlePageClick = useCallback<
    Required<ReactPaginateProps>['onPageChange']
  >(
    ({ selected }) => {
      updateTable({ page: selected + 1, query });
    },
    [query, updateTable],
  );

  const headers = useMemo<string[]>(() => {
    if (tableDataPage) {
      const [first] = tableDataPage;
      const mappedTranslated = getHeadersFromData(translated, first);
      return Array.from(mappedTranslated.values());
    }

    return [];
  }, [tableDataPage, translated]);

  const handleClick = useCallback<HandlerLink>(
    tableData => {
      push(`${url}/${tableData.id}`, { tableData });
    },
    [push, url],
  );

  const headerEl = useMemo(
    () =>
      headers
        .filter(key => key !== 'id')
        .map(header => (
          <TableHeaderCell key={header}>{header}</TableHeaderCell>
        )),
    [headers],
  );

  const body = useMemo(
    () =>
      tableDataPage?.map(row => {
        const { id, ...rowWithoutId } = row;
        return (
          <TableRow key={uuidV4()} onClick={() => handleClick(row)}>
            {Object.values(rowWithoutId).map(cell => (
              <TableCell key={uuidV4()}>{cell ?? <em>N/A</em>}</TableCell>
            ))}
            <TableCellIcon colSpan={2}>
              <TableIcon />
            </TableCellIcon>
          </TableRow>
        );
      }),
    [handleClick, tableDataPage],
  );

  return (
    <>
      <Loading {...{ isLoading }} />
      <section>
        {!isLoading && tableDataPage ? (
          <Wrapper>
            <Container>
              <TableHeader>
                <TableRow>
                  {headerEl}
                  <TableHeaderCellIcon />
                </TableRow>
              </TableHeader>
              <TableBody>{body}</TableBody>
            </Container>
          </Wrapper>
        ) : (
          <h1>Sem dados.</h1>
        )}
        <TableFooter>
          <PaginateWrapper>
            <Paginate
              {...{ pageCount }}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              previousLabel="Anterior"
              nextLabel="Próximo"
              onPageChange={handlePageClick}
              containerClassName="pagination"
              previousClassName="paginationItem paginationPrevious"
              nextClassName="paginationItem"
              disabledClassName="paginationLinkDisabled"
              activeClassName="paginationLinkActive"
              pageClassName="paginationLink paginationItem"
            />
          </PaginateWrapper>
        </TableFooter>
      </section>
    </>
  );
};

export default Table;
