import { ButtonHTMLAttributes, ReactElement } from 'react';

import { Loading } from '~/components';

import { Container } from './styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

const ButtonFilled = ({
  children,
  isLoading,
  type = 'button',
  ...rest
}: ButtonProps): ReactElement => (
  <Container {...{ type }} {...rest}>
    {isLoading ? <Loading /> : children}
  </Container>
);

export default ButtonFilled;
