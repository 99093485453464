import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0px 8px 15px rgba(62, 62, 62, 0.141);
  background: transparent
    linear-gradient(
      106deg,
      ${props => props.theme.colors.primary} 0%,
      #fdf5a6 100%
    )
    0% 0% no-repeat padding-box;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  color: white;
  text-transform: uppercase;
  text-align: center;
  column-gap: 1.5rem;
  border-radius: 8px;

  margin-right: 2rem;

  :last-child {
    margin-right: 0;
  }
`;

export const Image = styled.img`
  max-width: 50px;
  width: 100%;
  height: auto;
  display: block;
`;
export const Text = styled.h2`
  font-size: 3rem;
`;
export const SubText = styled.small`
  font-size: 1.75rem;
`;
