import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import { NamedProps } from 'react-select';

import { TrashIcon } from '~/assets/icons';
import { ButtonTransparent, Select } from '~/components';
import { getMonthNames, hasNativeMonth } from '~/utils';

import { Container, Group, Title, InputMonth } from './styles';

import SearchIcon from '../SearchIcon';

type MonthFilter =
  | {
      month: string;
      year: string;
    }
  | {
      yearMonth: string;
    };

interface ReportFilterDialogProps {
  setVisibility(visible: boolean): void;
  setDateFilter(date: Date): void;
}

const ReportFilterDialog = ({
  setVisibility,
  setDateFilter,
}: ReportFilterDialogProps): ReactElement => {
  const months = useRef<string[]>(getMonthNames());
  const formRef = useRef<FormHandles>(null);
  const [years, setYears] = useState<number[]>([]);
  const { current: currentDate } = useRef<Date>(new Date());
  const { current: isNativeMonth } = useRef<boolean>(hasNativeMonth());
  const releaseYear = parseInt(
    process.env.REACT_APP_REPORT_RELEASE_YEAR ?? '2000',
    10,
  );

  useEffect(() => {
    if (!isNativeMonth) {
      const currentYear = currentDate.getFullYear();
      const yearsCollection: number[] = new Array(
        currentYear - releaseYear + 1,
      ).fill(0);
      const yearsSequence = yearsCollection.map((_, i) => currentYear - i);
      setYears(yearsSequence);
    }
  }, [currentDate, isNativeMonth, releaseYear]);

  const handleSubmit = useCallback<SubmitHandler<MonthFilter>>(
    queryDate => {
      formRef.current?.setErrors({});
      let isValid = isNativeMonth;

      if (!isNativeMonth) {
        const fields = ['month', 'year'];
        if (fields.every(field => field in queryDate)) {
          const entries = Object.entries(queryDate);
          const missingValues = entries
            .filter(
              ([key, value]) => value.length === 0 && fields.includes(key),
            )
            .map(([key]) => key);

          if (missingValues.length > 0) {
            const errors: Record<string, string> = missingValues.reduce(
              (acc, key) => ({ ...acc, [key]: 'O campo é requirido' }),
              {},
            );
            formRef.current?.setErrors(errors);
          } else {
            isValid = true;
          }
        }
      }

      if (isValid) {
        const [year, month] =
          'year' in queryDate && 'month' in queryDate
            ? [queryDate.year, queryDate.month]
            : queryDate.yearMonth.split('-');
        const date = new Date(+year, +month - 1);
        setDateFilter(date);
        setVisibility(false);
      }
    },
    [isNativeMonth, setDateFilter, setVisibility],
  );

  const optionsMonth = useMemo<NamedProps['options']>(
    () =>
      months.current.map((month, index) => ({
        value: (index + 1).toString().padStart(2, '0'),
        label: month,
      })),
    [months],
  );

  const optionsYear = useMemo<NamedProps['options']>(
    () =>
      years.map(year => {
        const yearString = year.toString();

        return {
          value: yearString,
          label: yearString,
        };
      }),
    [years],
  );

  const selectGroup = useMemo((): ReactElement => {
    if (isNativeMonth) {
      const monthNumber = currentDate.getMonth() + 1;
      const monthString = monthNumber.toString();
      const monthPad = monthString.padStart(2, '0');

      const yearNumber = currentDate.getFullYear();

      return (
        <InputMonth
          type="month"
          name="yearMonth"
          min={`${releaseYear}-01`}
          max={`${yearNumber}-${monthPad}`}
          required
        />
      );
    }
    return (
      <>
        <Select placeholder="Mês" name="month" options={optionsMonth} />
        <Select placeholder="Ano" name="year" options={optionsYear} />
      </>
    );
  }, [currentDate, isNativeMonth, optionsMonth, optionsYear, releaseYear]);

  const handleClear = (): void => {
    formRef.current?.reset();
  };

  return (
    <Container>
      <Title>Filtrar</Title>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Group>
          {selectGroup}
          <ButtonTransparent type="submit">
            <SearchIcon />
          </ButtonTransparent>
          <ButtonTransparent type="reset" onClick={handleClear}>
            <TrashIcon />
          </ButtonTransparent>
        </Group>
      </Form>
    </Container>
  );
};

export default ReportFilterDialog;
