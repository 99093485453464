import styled from 'styled-components';

import ButtonFilled from '../ButtonFilled';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 5rem;
  column-gap: 8rem;
`;
export const Group = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Field = styled.span`
  font-weight: bold;
  color: #2c2c2c;
  display: block;
`;

export const Value = styled.span``;

export const Footer = styled.footer`
  grid-column: 1 / -1;
`;

export const ActionButton = styled(ButtonFilled)`
  padding: 12px 48px;
  margin-top: 0;
  width: auto;

  :disabled {
    background-color: ${props => props.theme.colors.disabled};
  }
`;
