import styled from 'styled-components';

import Card from '~/components/Card';
import TitleDashboard from '~/components/TitleDashboard';

export const Container = styled.div``;

export const Title = styled(TitleDashboard)`
  margin-bottom: 4rem;
`;

export const Wrapper = styled(Card)`
  margin-top: 2rem;
  padding: 24px;
`;
