import { ReactElement } from 'react';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

const ChevronLeft = (
  props: Omit<FontAwesomeIconProps, 'icon'>,
): ReactElement => <FontAwesomeIcon {...props} icon={faChevronLeft} />;

export default ChevronLeft;
