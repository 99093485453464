import { ReactElement, ReactNode } from 'react';

import { AuthProvider } from './auth';
import { AppThemeProvider } from './theme';
import { TranslatedProvider } from './translated';

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider = ({ children }: AppProviderProps): ReactElement => (
  <AppThemeProvider>
    <AuthProvider>
      <TranslatedProvider>{children}</TranslatedProvider>
    </AuthProvider>
  </AppThemeProvider>
);

export default AppProvider;
