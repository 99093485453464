import { Link as LinkBase } from 'react-router-dom';
import styled from 'styled-components';

import { Card as CardBase } from '~/components';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  max-width: 768px;
`;

export const Link = styled(LinkBase)`
  flex: 1;
  color: inherit;

  small {
    font-weight: normal;
  }
`;

export const GroupCard = styled.div`
  display: flex;
  gap: 1rem;
  flex: 1;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 3rem;
`;

export const Image = styled.img``;
export const Title = styled.h4``;
export const Subtitle = styled.small``;

export const Card = styled(CardBase)`
  display: flex;
  height: 100%;
  flex-flow: row wrap;
  gap: 2rem;
`;

export const GroupText = styled.div`
  margin: auto;
`;

export const CardTitle = styled(CardBase)`
  width: 100%;
`;
