import { useState, useCallback, ReactElement } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ImageLogo } from '~/assets/images';
import { ButtonFilled } from '~/components';
import { useAuth } from '~/hook/auth';
import { SignInForgetDialog } from '~/pages';
import { SignInData } from '~/typings/User';

import {
  Container,
  Form,
  Header,
  Input,
  LinkForget,
  Logo,
  Wrapper,
} from './styles';

const SignIn = (): ReactElement => {
  const [isVisibleModalForget, setModalVisibility] = useState(false);
  const history = useHistory();
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      try {
        await signIn({
          email: data.email,
          password: data.password,
        });

        history.push('/dashboard');
      } catch (err) {
        if (err instanceof TypeError) {
          toast.error('Ocorreu um erro na rede, tente novamente mais tarde');
        } else if (err instanceof Error) {
          toast.error(
            `${err.message}: Falha na autenticação, verifique seus dados.`,
          );
        }
      }
    },
    [history, signIn],
  );

  const handleOpenForgetDialog = (): void => {
    setModalVisibility(true);
  };

  return (
    <Container>
      <Wrapper>
        <Header>
          <Logo alt="Logo do seu dot net" src={ImageLogo} />
        </Header>
        <Form onSubmit={handleSubmit} method="POST">
          <Input type="email" name="email" placeholder="E-mail" required />
          <Input
            type="password"
            name="password"
            placeholder="Senha"
            minLength={6}
            required
          />
          <LinkForget type="button" onClick={handleOpenForgetDialog}>
            Esqueci minha senha
          </LinkForget>
          <ButtonFilled type="submit">Entrar</ButtonFilled>
        </Form>
        {isVisibleModalForget && (
          <SignInForgetDialog
            isHidden={isVisibleModalForget}
            setHidden={setModalVisibility}
          />
        )}
      </Wrapper>
    </Container>
  );
};

export default SignIn;
