import styled from 'styled-components';

import { ChevronDown } from '~/assets/icons';

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  padding: 1rem;
  margin-left: auto;
`;

export const Icon = styled(ChevronDown)`
  margin-left: 1rem;
`;

export const UserImage = styled.img`
  display: block;
  margin-right: 1rem;
`;
export const UserText = styled.span`
  color: white;
`;

export const ListActions = styled.nav`
  position: absolute;
  background-color: #2c2c2c;
  left: 0;
  right: 0;
  bottom: -2rem;
`;
