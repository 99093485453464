import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FormHandles, SubmitHandler } from '@unform/core';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TrashIcon } from '~/assets/icons';
import { ButtonTransparent, DetailsWrapper, Loading } from '~/components';

import {
  Container,
  AnchorDownload,
  Button,
  Title,
  Form,
  Group,
  Input,
  Label,
  SearchIcon,
} from './styles';

interface PageDownloadProps {
  handleDownload(query?: string): void;
  url?: string;
  setUrl(url?: string): void;
  showFilter?: boolean;
}

interface QueryName {
  queryByName: string;
}

const PageDownload = ({
  handleDownload,
  url,
  setUrl,
  showFilter = false,
}: PageDownloadProps): ReactElement => {
  const TIMEOUT = 1000;
  const { search } = useLocation();
  const { goBack } = useHistory();
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [isSearching, setIsSearching] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { push } = useHistory();

  const [query, setQuery] = useState<QueryName | undefined>(() => {
    const queryParam = new URLSearchParams(search);
    const value = queryParam.get('queryByName');

    if (value) {
      return { queryByName: value };
    }

    return undefined;
  });

  const clearUrl = useCallback(() => {
    if (url) {
      URL.revokeObjectURL(url);
      setUrl(undefined);
    }
  }, [setUrl, url]);

  const onDownload = useCallback((): void => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      clearUrl();
      goBack();
    }, TIMEOUT);
    setTimer(newTimer);
  }, [clearUrl, goBack, timer]);

  const handleGenerateReport = useCallback(
    (queryName?: string) => {
      try {
        setIsSearching(true);
        handleDownload(queryName || '');
        setIsSearching(false);
      } catch (e) {
        setIsSearching(false);
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
    },
    [handleDownload],
  );

  useEffect(() => {
    try {
      handleDownload();
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  }, [handleDownload]);

  useEffect(() => clearUrl, [clearUrl]);

  const handleSubmit = useCallback<SubmitHandler<QueryName>>(
    ({ queryByName }) => {
      clearUrl();
      setQuery({ queryByName });
      handleGenerateReport(queryByName);
    },
    [handleGenerateReport, clearUrl],
  );

  const handleClear = useCallback((): void => {
    clearUrl();
    push({ search: '' });
    setQuery(undefined);
    handleGenerateReport();
    formRef.current?.reset();
  }, [clearUrl, handleGenerateReport, push]);

  const formEl = useMemo(
    () => (
      <Form ref={formRef} onSubmit={handleSubmit} initialData={query}>
        <Label>
          Professor (a):
          <Group>
            <Input
              name="queryByName"
              type="query"
              maxLength={255}
              placeholder="Nome do professor"
            />
            <ButtonTransparent
              type="button"
              onClick={() => {
                formRef.current?.submitForm();
              }}
            >
              <SearchIcon />
            </ButtonTransparent>
            <ButtonTransparent type="button" onClick={handleClear}>
              <TrashIcon />
            </ButtonTransparent>
          </Group>
        </Label>
      </Form>
    ),
    [handleClear, handleSubmit, query],
  );

  return (
    <Container>
      <DetailsWrapper title="">
        {showFilter ? (
          <>{formEl}</>
        ) : (
          <Title>{url ? 'Carregado' : 'Carregando...'}</Title>
        )}

        {url ? (
          <AnchorDownload
            href={url}
            onClick={onDownload}
            download
            target="_blank"
            rel="noreferrer"
          >
            <Button>Clique para baixar</Button>
          </AnchorDownload>
        ) : (
          <>
            {!isSearching ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Loading isLoading />
              </div>
            ) : (
              <Button
                onClick={() => {
                  handleGenerateReport();
                }}
              >
                Gerar relatório
              </Button>
            )}
          </>
        )}
      </DetailsWrapper>
    </Container>
  );
};

export default PageDownload;
