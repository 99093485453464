import { ReactElement } from 'react';

import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '~/hook/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

// A ROTA É PRIVADA?  | USUÁRIO AUTENTICADO EXISTE?
// (isPrivate)        | (!!user)
// true               | true                = OK
// true               | false               = Redirecionar ele pro login
// false              | true                = Redirecionar para o dashboard
// false              | false               = OK

const Route = ({
  isPrivate = false,
  component: Component,
  ...rest
}: RouteProps): ReactElement => {
  const { user } = useAuth();
  const isAuthenticated = !!user;

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) =>
        isPrivate === isAuthenticated ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/dashboard',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default Route;
