import { ImgHTMLAttributes, ReactElement } from 'react';

import { IconSearch } from '~/assets/icons';

import { Image } from './styles';

const SearchIcon = (
  props: ImgHTMLAttributes<HTMLImageElement>,
): ReactElement => (
  <Image src={IconSearch} {...props} alt="Ícone de pesquisa" />
);

export default SearchIcon;
