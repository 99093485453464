import { ReactElement, useRef } from 'react';

import { CloseIcon } from '~/assets/icons';
import { ModalProps } from '~/typings/Modal';

import { Container, Wrapper, ButtonClose } from './styles';

/**
 * @description Component que ficara por cima
 */
const Modal = ({
  children,
  setVisibility,
  isVisible = false,
}: ModalProps): ReactElement => {
  const modalRef = useRef<HTMLElement>(null);
  const animationDurationMiliseconds = 500;

  function handleClose(): void {
    if (modalRef.current) {
      modalRef.current.animate([{ opacity: 1 }, { opacity: 0 }], {
        duration: animationDurationMiliseconds,
      });

      setTimeout(() => {
        setVisibility(false);
      }, animationDurationMiliseconds);
    }
  }

  return (
    <Container
      animationDurationMiliseconds={500}
      hidden={!isVisible}
      ref={modalRef}
    >
      <Wrapper>
        <ButtonClose onClick={handleClose} type="button">
          <CloseIcon />
        </ButtonClose>
        {children}
      </Wrapper>
    </Container>
  );
};

export default Modal;
