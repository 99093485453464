import { TableData } from '~/typings/Table';

function getHeadersFromData(
  translated: Map<string, string>,
  tableData: TableData,
): Map<string, string> {
  const mappedTranslated = new Map<string, string>();

  if (tableData) {
    const keys = Object.keys(tableData);
    keys.forEach(key => {
      mappedTranslated.set(key, translated.get(key) ?? key);
    });
  }

  return mappedTranslated;
}

export default getHeadersFromData;
