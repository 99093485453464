import styled from 'styled-components';

export const Container = styled.button`
  cursor: pointer;
  display: block;
  width: 100%;
  color: white;
  background: none;
  padding: 0.5rem 2rem;
`;
