import { ReactElement } from 'react';

import { Modal, Text } from '~/components';
import { ModalProps, ModalConfirmAction } from '~/typings/Modal';

import { ButtonCancel, ButtonConfirm, GroupBtn } from './styles';

interface ModalConfirmProps extends Omit<ModalProps, 'children'> {
  texts: string[];
  action: ModalConfirmAction;
}

function ModalConfirm({
  texts,
  action,
  ...modalProps
}: ModalConfirmProps): ReactElement {
  const handleClick = (response: boolean): void => {
    modalProps.setVisibility(false);
    action(response);
  };

  return (
    <Modal {...modalProps}>
      <Text texts={texts} />
      <GroupBtn>
        <ButtonConfirm onClick={() => handleClick(true)}>
          Confirmar
        </ButtonConfirm>
        <ButtonCancel onClick={() => handleClick(false)}>Fechar</ButtonCancel>
      </GroupBtn>
    </Modal>
  );
}

export default ModalConfirm;
