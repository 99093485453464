import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Dumbell, PlayButton, UserWhite } from '~/assets/icons';
import { GoldButton, TeacherDetails, DetailsWrapper } from '~/components';
import { useAuth } from '~/hook/auth';
import { teacherService } from '~/services/TeacherService';
import { TableData } from '~/typings/Table';

import { GroupBtn } from './styles';

interface BtnGold {
  value: number;
  icon: string;
}

type TypeCount = 'cursos' | 'treinos' | 'alunos';

interface Params {
  teacherId: string;
}

interface InfoNSet {
  (arg: Params): Promise<void>;
}

const TeacherStatsDetails = (): ReactElement => {
  const { push } = useHistory();
  const [data, setData] = useState<TableData>();
  const [title, setTitle] = useState('Nenhum usuário');
  const { teacherId } = useParams<Params>();
  const { token } = useAuth();

  const btnGoldIcon = useRef<Record<TypeCount, BtnGold['icon']>>({
    alunos: UserWhite,
    cursos: Dumbell,
    treinos: PlayButton,
  });

  const [btnGoldValue, setbtnValue] = useState<Record<TypeCount, number>>();

  const getInfoNSet = useCallback<InfoNSet>(
    async ({ teacherId: id }): Promise<void> => {
      try {
        const response = await teacherService.show({
          id,
          token,
        });

        const {
          countCourses: { total: countCourses },
          countStudents: { total: countStudents },
          countTrainings: { total: countTrainings },
          professional: {
            user: { name },
            ...rest
          },
        } = response;

        setData(rest);

        setbtnValue({
          cursos: countCourses,
          treinos: countTrainings,
          alunos: countStudents,
        });

        setTitle(name);
      } catch (e) {
        toast.warning('Usuário não localizado');
        push('/');
      }
    },
    [push, token],
  );

  useEffect(() => {
    getInfoNSet({ teacherId });
  }, [getInfoNSet, teacherId]);

  const footer = useMemo(
    () =>
      btnGoldValue && (
        <GroupBtn>
          {(Object.keys(btnGoldValue) as Array<TypeCount>).map(type => (
            <GoldButton
              key={type}
              icon={btnGoldIcon.current[type]}
              text={type}
              value={btnGoldValue[type]}
            />
          ))}
        </GroupBtn>
      ),
    [btnGoldValue],
  );

  return (
    <DetailsWrapper {...{ title, footer }}>
      {data ? <TeacherDetails tableData={data} /> : <h1>Sem dados.</h1>}
    </DetailsWrapper>
  );
};

export default TeacherStatsDetails;
