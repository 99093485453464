import { DefaultTheme } from 'styled-components';

const DefaultThemeImpl: DefaultTheme = {
  widthSidebarPainel: 200,
  colors: {
    primary: '#b88746',
    secondary: '#cacaca',
    background: '#f8f8f8',
    disabled: '#dedede',
  },
};

export default DefaultThemeImpl;
