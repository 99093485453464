import styled from 'styled-components';

import ButtonFilled from '~/components/ButtonFilled';

export const GroupBtn = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
`;
const ButtonShared = styled(ButtonFilled)``;
export const ButtonConfirm = styled(ButtonShared)`
  background-color: green;
`;
export const ButtonCancel = styled(ButtonShared)`
  background-color: #c53030;
  grid-column-start: -2;
`;
