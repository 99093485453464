import { NavLink as NavLinkBase } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    height: 125px;
    flex-direction: row;
    justify-content: space-around;
    font-size: 1.5rem;
  }
`;

export const Image = styled.img`
  display: block;
  margin-bottom: 1rem;
`;
export const TextWrapper = styled.span`
  width: 100%;
  text-align: center;
  color: white;
  text-decoration: none;
`;

export const NavLink = styled(NavLinkBase)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (min-width: 1025px) {
    padding-left: 2rem;
    padding-right: 2rem;

    & + & {
      margin-top: 1rem;
    }
  }

  &.active {
    background-color: ${props => props.theme.colors.primary};
  }

  @media (max-width: 1024px) {
    flex: 1;
  }
`;
