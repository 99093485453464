import { ReactElement } from 'react';

import { Paragraph } from './styles';

interface TextProps {
  texts: string[];
}

const Text = ({ texts }: TextProps): ReactElement => {
  const paragraph = texts.map((text, key) => (
    <Paragraph {...{ key }}>{text}</Paragraph>
  ));

  return <>{paragraph}</>;
};

export default Text;
