import { ReactElement } from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

const ChevronDown = (
  props: Omit<FontAwesomeIconProps, 'icon'>,
): ReactElement => <FontAwesomeIcon icon={faChevronDown} {...props} />;

export default ChevronDown;
