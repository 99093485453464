import { InputHTMLAttributes, ReactElement, useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Error, InputEl } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const Input = ({ name, ...rest }: InputProps): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <InputEl
        className={error && 'hasError'}
        ref={inputRef}
        {...{ defaultValue }}
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </>
  );
};

export default Input;
