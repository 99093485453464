import { Form as Unform } from '@unform/web';
import styled from 'styled-components';

import { BackgroundLogin } from '~/assets/images';
import { Input as InputBase } from '~/components';

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c2c2c;
  height: 100vh;
  color: white;
  background-image: url(${BackgroundLogin});
  background-size: cover;
`;

export const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.header`
  width: 100%;
  max-width: 60%;
`;

export const LinkForget = styled.button`
  color: #fdf5a6;
  text-decoration: none;
  font-weight: bold;
  box-shadow: none;
  background: none;
  margin: 1rem auto;
  display: block;
  text-align: center;
  margin-top: 0.5rem;
  font-size: 1.5rem;
`;

export const Logo = styled.img`
  display: block;
  width: 100%;
  max-width: 200px;
  height: auto;
  margin: auto;
`;

export const Form = styled(Unform)`
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const Input = styled(InputBase)`
  display: block;
  width: 100%;
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  font-family: 'Open Sans', sans-serif;
  border: none;
  border-bottom: 1px solid white;
  color: white;

  &::placeholder {
    opacity: 1;
  }

  & + & {
    margin-top: 1rem;
  }
`;

export const Footer = styled.footer`
  margin-top: 1.5rem;
`;
