import { useRef } from 'react';
import { ReactElement } from 'react';
import { useEffect } from 'react';

import { useField } from '@unform/core';
import StateManager, {
  OptionTypeBase,
  NamedProps as SelectProps,
  CommonProps,
} from 'react-select';

import { Selector } from './styles';

interface Props extends Omit<SelectProps<OptionTypeBase>, 'theme'> {
  name: string;
}

interface TupleValue extends OptionTypeBase {
  label: string;
  value: string;
}

interface RefState {
  state: {
    value: TupleValue | TupleValue[] | undefined;
  };
}

function Select({ name, ...props }: Props): ReactElement {
  const selectRef = useRef<StateManager>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: RefState) => {
        if (props.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: TupleValue) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        if (!Array.isArray(ref.state.value)) {
          return ref.state.value.value;
        }

        return '';
      },
      clearValue: (ref: { select: CommonProps<OptionTypeBase, boolean> }) => {
        ref.select.clearValue();
      },
    });
  }, [defaultValue, fieldName, props.isMulti, registerField]);

  return (
    <Selector
      classNamePrefix="react-select"
      title={error || undefined}
      hasError={error !== undefined}
      ref={selectRef}
      {...{ defaultValue, ...props }}
    />
  );
}

export default Select;
