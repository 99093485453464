import { ReactElement, useEffect, useMemo, useState } from 'react';

import { useTranslated } from '~/hook/translated';
import { TableDataTypes, TableDetailsData } from '~/typings/Table';
import {
  getHeadersFromData,
  transformToCurrency,
  transformToDate,
} from '~/utils';

/**
 * @todo see if we can merge this with PlainDetails
 */
const TeacherDetails = ({ tableData }: TableDetailsData): ReactElement => {
  const { translated, keysRemoved } = useTranslated();
  const [mapped, setMapped] =
    useState<Map<string, TableDataTypes | Record<string, TableDataTypes>>>();

  useEffect(() => {
    const formatCurrency = transformToCurrency();
    const { amount_receivable, updated_at, created_at, ...rest } = tableData;
    const tableEntries = new Map(Object.entries(rest));

    /**
     * Transform currency
     */
    if (amount_receivable && typeof amount_receivable === 'number') {
      const formattedValue = formatCurrency(amount_receivable);
      tableEntries.set('amount_receivable', formattedValue);
    }

    /**
     * Transform Date
     */
    const dates = Object.entries({ updated_at, created_at });
    dates.forEach(([key, value]) => {
      if (typeof value === 'string') {
        const date = transformToDate(value);
        tableEntries.set(key, date);
      }
    });

    setMapped(tableEntries);
  }, [tableData, translated]);

  const body = useMemo(() => {
    if (!mapped) return <h1>Sem dados.</h1>;

    const headers = Array.from(mapped.keys());
    const headersTranslated = getHeadersFromData(translated, tableData);

    const headersAllowed = headers.filter(key => !keysRemoved.includes(key));

    const content = headersAllowed.map(header => {
      const value = mapped.get(header);
      const isEmptyString = value === '';

      return (
        <p key={header}>
          <strong>{headersTranslated?.get(header) ?? header}: </strong>
          {isEmptyString || !value ? <em>Nada consta</em> : value}
        </p>
      );
    });

    return content;
  }, [keysRemoved, mapped, tableData, translated]);

  return <>{body}</>;
};

export default TeacherDetails;
