import { ReactElement, ReactNode, useState } from 'react';

import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DetailsWrapper, Details, Loading } from '~/components';
import { useAuth } from '~/hook/auth';
import { FinancialTableData } from '~/models/Financial';
import { financialService } from '~/services/FinancialService';
import { ModalConfirmAction } from '~/typings/Modal';

interface FinancialDetailsProps {
  children?: ReactNode;
}

interface StateProps {
  tableData: FinancialTableData;
}

const FinancialDetails = (props: FinancialDetailsProps): ReactElement => {
  const { state } = useLocation<StateProps>();
  const { push } = useHistory();
  const [isLoading, setLoading] = useState(false);
  const { token } = useAuth();

  if (!state) {
    toast.error('No financial details found');
    return <Redirect to="/" />;
  }

  const texts = [
    'Você tem certeza que quer cancelar?',
    'Nenhuma ação poderá ser desfeita depois dessa operação.',
  ];

  const action: ModalConfirmAction = async (
    response: boolean,
  ): Promise<void> => {
    if (response) {
      let redirectToRoot = false;

      setLoading(true);

      try {
        await financialService.cancelationPlan({
          payment_id: state.tableData.id,
          token,
        });
        redirectToRoot = true;

        toast.success('Assinatura cancelada com sucesso.');
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      } finally {
        setLoading(false);
        if (redirectToRoot) {
          push('/');
        }
      }
    }
  };

  return (
    <DetailsWrapper title="Transações Financeiras" {...props}>
      <Loading {...{ isLoading }} />
      <Details
        modal={{
          detailsTitle: 'Cancelar assinatura',
          modalTexts: texts,
          actionConfirmDialog: action,
          actionIsDisabled: isLoading,
        }}
      />
    </DetailsWrapper>
  );
};

export default FinancialDetails;
