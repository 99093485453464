import styled from 'styled-components';

export const Error = styled.span`
  color: firebrick;
`;

export const InputEl = styled.input`
  &.hasError {
    color: red;
  }
`;
