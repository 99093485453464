import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-flow: row wrap;
  background-color: #f8f8f8;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const Sidebar = styled.div`
  position: sticky;
  background-color: #1c1c1c;

  @media (min-width: 1025px) {
    top: 100px;
    width: 200px;
    height: calc(100vh - 100px);
  }

  @media (max-width: 1024px) {
    position: fixed;
    order: 1;
    width: 100%;
    bottom: 0;
  }
`;

export const Content = styled.section`
  display: flex;
  overflow: auto;
  align-items: flex-start;
  flex-flow: row wrap;
  flex: 1;
  padding: 64px;

  @media (max-width: 1024px) {
    padding: 16px 16px calc(125px + 16px);
  }
`;
