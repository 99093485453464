export const formatPhoneNumber = (phoneNumber: string): string => {
  const { length } = phoneNumber;
  const ddd = phoneNumber.substring(0, 2);
  const firstDigits =
    length === 10 ? phoneNumber.substring(2, 6) : phoneNumber.substring(2, 7);
  const finalDigits =
    length === 10 ? phoneNumber.substring(6, 11) : phoneNumber.substring(7, 11);

  return `(${ddd}) ${firstDigits}-${finalDigits}`;
};
