import { ReactElement } from 'react';

import { UserIcon } from '~/assets/icons';
import { ImageLogo } from '~/assets/images';
import AccountDropdown from '~/components/Header/AccountDropdown';
import Dropdown from '~/components/Header/Dropdown';

import { Logo, LogoContainer, Container } from './styles';

interface HeaderProps {
  name: string;
}

const Header = ({ name }: HeaderProps): ReactElement => (
  <Container>
    <LogoContainer>
      <Logo src={ImageLogo} alt="Logo do Seunet" />
    </LogoContainer>
    <Dropdown {...{ name }} image={UserIcon}>
      <AccountDropdown />
    </Dropdown>
  </Container>
);

export default Header;
