import styled from 'styled-components';

import { Card as CardBase } from '~/components';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Card = styled(CardBase)`
  width: 100%;
  padding: 16px;
  max-width: 1024px;
`;

export const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  justify-content: space-between;
`;
