import {
  ForgetPasswordResponse,
  SignInData,
  SignResponse,
  ForgetPasswordRequest,
} from '~/typings/User';

import Service from './Service';

export default class AuthServiceImpl extends Service {
  private readonly methodDefault = 'POST';

  async login(credentials: SignInData): Promise<SignResponse> {
    const body = JSON.stringify(credentials);

    const response = await Service.api.fetchAndReturnJSON<SignResponse>({
      pathname: '/admins/authenticate',
      body,
      method: this.methodDefault,
    });

    return response;
  }

  async forgetPassword({
    email,
  }: ForgetPasswordRequest): Promise<ForgetPasswordResponse> {
    const body = JSON.stringify({ email });

    const response =
      await Service.api.fetchAndReturnJSON<ForgetPasswordResponse>({
        pathname: `${this.pathname}/forgotpassword`,
        body,
        method: this.methodDefault,
      });

    return response;
  }
}

export const authService = new AuthServiceImpl('/auth');
