import { ReactElement, ReactNode } from 'react';

import { Title } from './styles';

interface TitleDashboardProps {
  children: ReactNode;
}

const TitleDashboard = (props: TitleDashboardProps): ReactElement => (
  <Title {...props} />
);

export default TitleDashboard;
