import { TeacherData, TeacherTableData } from '~/models/Teacher';
import { formatPhoneNumber } from '~/utils/formatPhone';

export default function TeacherStatsFilter(
  data: TeacherData[],
): TeacherTableData[] {
  const teacherData: TeacherTableData[] = data.map(
    ({ id, cref, user: { name: teacher, email, cell_phone } }) => ({
      id,
      cref,
      teacher,
      email,
      cell_phone: cell_phone ? formatPhoneNumber(cell_phone) : undefined,
    }),
  );

  return teacherData;
}
