import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { useHistory } from 'react-router-dom';

import { User } from '~/models';
import { authService } from '~/services/AuthService';
import { SignInData } from '~/typings/User';

const prefix = process.env.REACT_APP_NAME ?? 'SEUNET:';

interface AuthState {
  user: User;
  token: string;
}

interface AuthContextData {
  user: User;
  token: string;
  signOut(): void;
  signIn(user: SignInData): Promise<void | never>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children }: AuthProviderProps): ReactElement => {
  const { push } = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem(`${prefix}user`);
    const token = localStorage.getItem(`${prefix}token`);

    if (user && token) {
      return { user: JSON.parse(user), token };
    }

    return {} as AuthState;
  });

  const signIn = async (credentials: SignInData): Promise<void | never> => {
    const response = await authService.login(credentials);

    if (!response.message) {
      const userString = JSON.stringify(response.user);
      const tokenString = `Bearer ${response.data.token}`;
      localStorage.setItem(`${prefix}user`, userString);
      localStorage.setItem(`${prefix}token`, tokenString);
      setData({ user: response.user, token: tokenString });
    } else {
      throw new Error(response.message);
    }
  };

  const signOut = useCallback(() => {
    localStorage.removeItem(`${prefix}user`);
    localStorage.removeItem(`${prefix}token`);

    push('/');

    setData({} as AuthState);
  }, [push]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        signOut,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth };
