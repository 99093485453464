import { ButtonHTMLAttributes, ReactElement } from 'react';

import { useHistory } from 'react-router-dom';

import { Container, Icon } from './styles';

interface BackButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  pushToRoot?: boolean;
}

const BackButton = ({
  pushToRoot = false,
  ...props
}: BackButtonProps): ReactElement => {
  const { goBack, push } = useHistory();

  const handleButton = (): void => {
    if (pushToRoot) {
      push('/');
    } else {
      goBack();
    }
  };

  return (
    <Container type="button" {...props} onClick={handleButton}>
      <Icon /> Voltar
    </Container>
  );
};

export default BackButton;
