import { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';

import { toast } from 'react-toastify';

import { ButtonFilled, Loading, Modal } from '~/components';

import { Input, Subtitle, Title } from './styles';

interface SignInForgetDialogProp {
  isHidden: boolean;
  setHidden: (param: boolean) => void;
}

enum FormType {
  FORGET_EMAIL,
  VALIDATE_CODE,
}

const SignInForgetDialog = ({
  isHidden,
  setHidden,
}: SignInForgetDialogProp): ReactElement => {
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(FormType.FORGET_EMAIL);
  const [title, setTitle] = useState<string>('e-mail');

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const entries = formData.entries();
    const data = Object.fromEntries(entries);
    const { forgetEmail } = data;

    if (typeof forgetEmail === 'string') {
      try {
        setPage(oldpage => oldpage + 1);
        // const response = await authService.forgetPassword(forgetEmail);
        // toast.info(response.message);
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
      }
    }
  };

  useEffect(() => {
    if (page) {
      setLoading(true);
      const timer = setTimeout((): void => {
        setLoading(false);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

    return undefined;
  }, [page]);

  const inputScreen = useMemo((): ReactElement => {
    switch (page) {
      case FormType.VALIDATE_CODE:
        setTitle('código');

        return (
          <Input
            name="code"
            type="text"
            minLength={6}
            maxLength={6}
            pattern={/\d{6}/.toString()}
            placeholder="Code"
          />
        );

      case FormType.FORGET_EMAIL:
      default:
        return (
          <Input
            name="forgetEmail"
            type="email"
            placeholder="E-mail"
            required
          />
        );
    }
  }, [page]);

  const body = useMemo(
    (): ReactElement => (
      <>
        <Loading {...{ isLoading }} />
        {isLoading || (
          <>
            <Title>Esqueci minha senha</Title>
            <Subtitle>Insira seu {title} abaixo.</Subtitle>
            <form onSubmit={handleSubmit}>
              {inputScreen}
              <ButtonFilled type="submit">Enviar</ButtonFilled>
            </form>
          </>
        )}
      </>
    ),
    [inputScreen, isLoading, title],
  );

  return (
    <Modal {...{ isVisible: isHidden, setVisibility: setHidden }}>{body}</Modal>
  );
};

export default SignInForgetDialog;
