import { ReactElement, useState } from 'react';

import { SubmitHandler } from '@unform/core';
import { useLocation } from 'react-router-dom';
import { URLSearchParams } from 'url';

import { TitleDashboard, Table, Search } from '~/components';
import { callbackPageWithCount, Query } from '~/typings/Table';

import { Container, Card, Header } from './styles';

interface SearchProps {
  name: string;
  placeholder?: string;
}

interface PageProps {
  title: string;
  search?: SearchProps;
  handlePageData: callbackPageWithCount;
}

function useQuery(): URLSearchParams {
  return new window.URLSearchParams(useLocation().search);
}

const Page = ({ title, search, handlePageData }: PageProps): ReactElement => {
  const queryParams = useQuery();

  const [query, setQuery] = useState<string | undefined>(
    queryParams.get('query') ?? undefined,
  );

  const updateQuery: SubmitHandler<Query> = data => {
    if (search) {
      const new_query = data[search.name];
      if (new_query.length > 0) {
        setQuery(new_query);
      } else {
        setQuery(undefined);
      }
    }
  };

  return (
    <Container>
      <Card>
        <Header>
          <TitleDashboard>{title}</TitleDashboard>
          {search && <Search {...{ ...search, updateQuery }} />}
        </Header>
        <Table {...{ handlePageData, query }} />
      </Card>
    </Container>
  );
};

export default Page;
