import { Form as FormBase } from '@unform/web';
import styled from 'styled-components';

import { Search as SearchBase } from '~/components';
import { Input as InputBase, SearchIcon as SearchIconBase } from '~/components';
import { ButtonFilled } from '~/components';
import TitleDashboard from '~/components/TitleDashboard';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
`;

export const Button = styled(ButtonFilled)`
  padding: 1rem 2rem;
`;

export const Title = styled(TitleDashboard)`
  margin-bottom: 4rem;
`;

export const AnchorDownload = styled.a`
  color: ${props => props.theme.colors.background};
`;

export const Group = styled.span`
  position: relative;
  display: flex;
  width: min-content;
  justify-content: center;
  padding: 0.75rem 2rem;
  margin-top: 1rem;
  border-bottom: 1px solid #2c2c2c;
`;
export const Input = styled(InputBase)`
  border: none;
`;
export const Label = styled.label`
  font-weight: bold;
`;
export const Form = styled(FormBase)`
  margin-top: 3rem;
`;
export const Search = styled(SearchBase)`
  margin-left: 1rem;
`;
export const SearchIcon = styled(SearchIconBase)`
  width: 1.75rem;
  height: 1.75rem;
`;
