import { ReactElement } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AppProvider from '~/hook';
import GlobalStyle from '~/styles/global';

import Routes from './routes';

const App = (): ReactElement => (
  <BrowserRouter>
    <AppProvider>
      <Routes />
      <GlobalStyle />
      <ToastContainer />
    </AppProvider>
  </BrowserRouter>
);

export default App;
