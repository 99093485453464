import { Form as FormBase } from '@unform/web';
import styled from 'styled-components';

import { Input } from '~/components';

export const SearchForm = styled(FormBase)`
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 3rem;
  padding: 0.5rem 2rem;
  transition: border-color 500ms ease-in-out;
  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.primary};
  }
`;

export const SearchLabel = styled.label``;
export const SearchInput = styled(Input)`
  border: none;
  margin-left: 1rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
`;
