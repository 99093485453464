import { FinancialResponse } from '~/models/Financial';
import ResourceService from '~/services/ResourceService';
import { MessageServer } from '~/typings/Server';
import { ServiceArgs } from '~/typings/Services';

import Service from './Service';

interface CancelationPlanArg extends ServiceArgs {
  payment_id: number;
}

export default class FinancialServiceImpl extends ResourceService<FinancialResponse> {
  async cancelationPlan({
    payment_id,
    ...rest
  }: CancelationPlanArg): Promise<MessageServer> {
    const response = await Service.api.fetchAndReturnJSON<MessageServer>({
      method: 'POST',
      body: JSON.stringify({ payment_id }),
      pathname: `${this.pathname}/cancelation`,
      ...rest,
    });

    return response;
  }

  static async downloadReport(
    args: ServiceArgs,
    professionalId: number,
  ): Promise<Blob> {
    const response = await Service.api.fetchAndReturnBlob({
      pathname: `/admins/reports/sales/${professionalId}`,
      ...args,
    });

    return response;
  }
}

export const financialService = new FinancialServiceImpl('/admins/plans');
