import { ReactElement, ReactNode, useContext, useRef } from 'react';
import { createContext } from 'react';

interface TranslatedContextData {
  translated: Map<string, string>;
  keysRemoved: string[];
}

const translatedObject: Record<string, string> = {
  cref: 'CREF',
  teacher: 'Professor (a)',
  email: 'E-mail',
  cell_phone: 'Telefone',
  created_at: 'Criado em',
  updated_at: 'Atualizado em',
  description: 'Descrição',
  amount_receivable: 'Total a receber',
  nick_name: 'Nome público',
  pix_key: 'Chave PIX',
  due_date: 'Dia de recebimento',
  courses: 'Cursos',
  name: 'Nome',
  students: 'Estudantes',
  date: 'Data',
  student_name: 'Aluno (a)',
  student_cpf: 'CPF do aluno (a)',
  subject: 'Plano/Curso',
  rate: 'Taxa',
  type: 'Tipo',
  title: 'Título',
  price: 'Preço',
  cpf: 'CPF',
  course: 'curso',
  training: 'treino',
  live: 'ao vivo',
  sum: 'Valor total',
};

const keysRemovedArray: string[] = [
  'id',
  'logo_key',
  'logo_path',
  'user_id',
  'public_name',
  'user_id',
  'user',
];

const TranlatedContext = createContext<TranslatedContextData>(
  {} as TranslatedContextData,
);

interface TranslatedProviderProps {
  children: ReactNode;
}

const TranslatedProvider = ({
  children,
}: TranslatedProviderProps): ReactElement => {
  const { current: translated = new Map<string, string>() } = useRef(
    new Map(Object.entries(translatedObject)),
  );

  const { current: keysRemoved = [] } = useRef(keysRemovedArray);

  return (
    <TranlatedContext.Provider value={{ translated, keysRemoved }}>
      {children}
    </TranlatedContext.Provider>
  );
};

function useTranslated(): TranslatedContextData {
  const context = useContext(TranlatedContext);
  return context;
}

export { TranslatedProvider, useTranslated };
